import type { Route } from 'nextjs-routes';

const TEMPLATE_MAP: Record<Route['pathname'], string> = {
  '/': 'Explorer',
  '/txs': 'Transactions',
  '/txs/kettle/[hash]': 'Kettle %hash% Transactions',
  '/tx/[hash]': 'Transaction %hash%',
  '/blocks': 'Blocks',
  '/block/[height_or_hash]': 'Block %height_or_hash%',
  '/accounts': 'Top Accounts',
  '/address/[hash]': 'Address details for %hash%',
  '/verified-contracts': 'Verified Contracts',
  '/contract-verification': 'Verify Contract',
  '/address/[hash]/contract-verification': 'Contract verification for %hash%',
  '/tokens': 'Tokens',
  '/token/[hash]': 'Token Details',
  '/token/[hash]/instance/[id]': 'NFT Instance',
  '/apps': 'Apps Marketplace',
  '/apps/[id]': 'Marketplace App',
  '/stats': 'Statistics',
  '/api-docs': 'REST API',
  '/graphiql': 'GraphQL',
  '/search-results': 'Search result for %q%',
  '/auth/profile': '- My Profile',
  '/account/watchlist': '- Watchlist',
  '/account/api-key': '- API Keys',
  '/account/custom-abi': '- Custom ABI',
  '/account/public-tags-request': '- Public Tag requests',
  '/account/tag-address': '- Private Tags',
  '/account/verified-addresses': '- My Verified Addresses',
  '/withdrawals': 'Withdrawals',
  '/visualize/sol2uml': 'Solidity UML Diagram',
  '/csv-export': 'Export data to CSV',
  '/deposits': 'Deposits (L1 > L2)',
  '/output-roots': 'Output Roots',
  '/batches': 'TX Batches (L2 Blocks)',
  '/batches/[number]': 'L2 TX Batch %number%',
  '/blobs/[hash]': 'Blob %hash% Details',
  '/ops': 'User Operations',
  '/op/[hash]': 'User Operation %hash%',
  '/404': 'Error - Page Not Found',
  '/name-domains': 'Domains Search and Resolve',
  '/name-domains/[name]': '%name% Domain Details',
  '/validators': 'Validators List',
  '/gas-tracker': 'Gas Tracker',

  // service routes, added only to make typescript happy
  '/login': 'Login',
  '/api/metrics': 'Node API Prometheus Metrics',
  '/api/log': 'Node API Request Log',
  '/api/media-type': 'Node API Media Type',
  '/api/proxy': 'Node API Proxy',
  '/api/csrf': 'Node API CSRF Token',
  '/api/healthz': 'Node API Health Check',
  '/auth/auth0': 'Authentication',
  '/auth/unverified-email': 'Unverified E-Mail',
};

const TEMPLATE_MAP_ENHANCED: Partial<Record<Route['pathname'], string>> = {
  '/token/[hash]': '%symbol% Token Details',
  '/token/[hash]/instance/[id]': 'Token Instance for %symbol%',
  '/apps/[id]': '- %app_name%',
  '/address/[hash]': 'Address Details for %domain_name%',
};

export function make(pathname: Route['pathname'], isEnriched = false) {
  const template = (isEnriched ? TEMPLATE_MAP_ENHANCED[pathname] : undefined) ?? TEMPLATE_MAP[pathname];

  return `%network_name% ${ template }`;
}
